import * as React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';

function getApolloClient(graphQLEndPoint: string) {
    return new ApolloClient({
        uri: graphQLEndPoint,
        cache: new InMemoryCache(),
        credentials: 'include'
    });
}

export function TUGApolloProvider({ graphQLEndPoint, children } : { graphQLEndPoint: string, children: React.ReactNode | React.ReactNode[] | null }) {
    return (
        <ApolloProvider client={getApolloClient(graphQLEndPoint)}>
            {children}
        </ApolloProvider>
    );
};
