import * as React from 'react';
import './style.css';

export type HeaderProps = {
  title: string,
  subtitle1: string,
  subtitle2?: string,
  src: string,
  logoSrc?: string,
  ctaEnabled: boolean,
  courseName?: string,
  institutionUrl?: string
}

export const InstitutionHeader = (props: HeaderProps) => (
  <div className="header-container">
    <div className="header-img" style={{ backgroundImage: `url(${props.src})` }}>
      <div className={`header-banner-container grid--gutter ${props.logoSrc && 'header-banner-container-logo'}`}>
        <div className="header-title-container">
          <h1 className="header-title">{props.title}</h1>
          <p className="header-subtitle">{`${props.subtitle1}${props.subtitle2 && ` | ${props.subtitle2}`}`}</p>
        </div>
        {props.logoSrc &&
          <div className="logo-container">
            <div className="logo-circle">
              <img className="header-banner-logo" src={props.logoSrc} alt={`Logo for ${props.title}`} />
            </div>
          </div>
        }
      </div>
    </div>
  </div>
);

export const CourseHeader = (props: HeaderProps) => (
  <div className="course-header-container">
    <div
      className="course-header-img"
      style={{ backgroundImage: `url(${props.src})` }}
    >
      <div className={`course-header-banner-container grid--gutter ${props.logoSrc && 'course-header-banner-container-logo'}`}>
        <div className="course-header-title-container">
          <h1 className="title-course">{props.courseName}</h1>
          <div className="desktop-course-uni-info">
            <a href={`/${props.institutionUrl}`} id="institution-url">
              <h1 data-institution-name={props.title} className="course-header-title">{props.title}</h1>
            </a>
            <p className="course-header-subtitle">{`${props.subtitle1}${props.subtitle2 && ` | ${props.subtitle2}`}`}</p>
          </div>
        </div>
        {props.logoSrc &&
          <div className="course-logo-container">
            <div className="logo-circle">
              <img className="header-banner-logo" src={props.logoSrc} alt={`Logo for ${props.title}`} />
            </div>
          </div>
        }
      </div>
    </div>
    <div className="mobile-course-uni-info">
      <a href={`/${props.institutionUrl}`} id="institution-url">
        <h1 data-institution-name={props.title} className="course-header-title">{props.title}</h1>
      </a>
      <p className="course-header-subtitle">{`${props.subtitle1}${props.subtitle2 && ` | ${props.subtitle2}`}`}</p>
    </div>
  </div>
);
