import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import { setupGoogleSignin, socialLoginQuery } from '@thestudentroom/package.accounts';
import { TUGApolloProvider } from '../../graphql/Apollo';

export default function GoogleOneTapWrapper({ googleClientId, graphQLEndPoint }: { googleClientId: string, graphQLEndPoint: string}) {
    return (
        <TUGApolloProvider graphQLEndPoint={graphQLEndPoint}>
            <GoogleOneTap googleClientId={googleClientId} />
        </TUGApolloProvider>
    );
}

const GoogleOneTap = ({ googleClientId }: { googleClientId: string}) => {
    const [getTokens] = useLazyQuery(socialLoginQuery);

    setupGoogleSignin({
        googleClientId: googleClientId,
        promptOneTap: true,
        context: 'signin',
        getTokens: getTokens,
        route: 'google-one-tap',
        website: 'TUG'
    });

    return null;
}
